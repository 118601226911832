import React from "react";
import './Main.css';
import Board from "../Board/Board";

function Main() {
    return (
  <div>
    <Board BoardName="최신글"/>
  </div>
);
};

export default Main;